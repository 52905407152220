import React from 'react';

import Container from 'common/container';
import ApplicationPage from 'site/application-page';

export default () => (
    <ApplicationPage>
        <Container className="max-w-xxxl py-24 text-center" />
    </ApplicationPage>
);
